import { useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import BLOB from "../images/blob.png"
import DEER from "../images/deer.gif"

const Error404 = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.setQueryData(["is-error-404"], true)

    return () => {
      queryClient.setQueryData(["is-error-404"], false)
    }
  }, [queryClient])

  return (
    <MainLayout contentBg="haze">
      <div className="container max-w-1120">
        <div className="error-404-inner">
          <div className="error-404-col-1">
            <p className="text-dusk text-sm font-semibold leading-130 tracking-0.14 mb-4">
              Error: 404 page not found
            </p>
            <h1 className="text-charcoal-900 text-4xl leading-[124%] tracking-[0.72px] mb-2">
              Oh deer!
            </h1>
            <p className="text-charcoal-900 text-xl font-bold leading-6 tracking-0.4">
              Just like trees, sometimes web pages need a little time to grow.
            </p>
          </div>
          <div className="error-404-col-2">
            <div className="relative w-242 md:w-auto max-w-full">
              <img src={BLOB} alt="" />
              <img src={DEER} alt="deer" className="error-404-deer" />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Error404
