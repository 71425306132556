import { useQueryClient } from "@tanstack/react-query"

import { useProfile } from "./react-query/queries/useProfile"
import { useAccessToken } from "../stores"
import { Profile } from "@/types"

const useIsEmailVerified = (): boolean => {
  const queryClient = useQueryClient()
  const accessToken = useAccessToken()

  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: !!accessToken,
  })

  const isEmailVerified = !!profile?.is_email_verified

  return isEmailVerified
}

export default useIsEmailVerified
