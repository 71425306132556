import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Form, Formik, FormikProps } from "formik"
import * as Yup from "yup"

import * as auth from "../api/auth"
import AuthLayout from "./_layouts/Auth"
import { ContentCard, ErrorCard } from "../components/Card"
import { SubmitButton } from "../components/SubmitButton"
import { WrappedInput } from "../components/Input"
import { useQueryParam, useResetPasswordConfirm } from "../hooks"
import { useScrubQuerystring } from "../hooks/useScrubQuerystring"

interface FormComponentProps {
  errorMessage?: string | null
  formikProps: FormikProps<{
    confirmPassword: string
    password: string
    token: string | null
  }>
}

const FormComponent = ({ errorMessage, formikProps }: FormComponentProps) => {
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  if (!formikProps.values.token && !errorMessage) {
    errorMessage =
      "Missing reset password token. Please ensure full URL was copied."
  }

  return (
    <ContentCard>
      <h3 className="text-lg md:text-2xl text-center mb-2">
        <span>Update your password</span>
      </h3>
      <hr className="border-cloud mb-4" />
      <Form className="space-y-4">
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}

        <div className="space-y-4">
          <div className="space-y-1">
            <WrappedInput
              data-test="input-password"
              label="New password"
              name="password"
              placeholder="Password"
              type={showNewPassword ? "text" : "password"}
              as="password"
              toggleShowPassword={() => setShowNewPassword(!showNewPassword)}
            />
          </div>
          <div className="space-y-1">
            <WrappedInput
              data-test="input-confirm"
              label="Confirm new password"
              name="confirmPassword"
              placeholder="Confirm password"
              type={showConfirmPassword ? "text" : "password"}
              as="password"
              toggleShowPassword={() =>
                setShowConfirmPassword(!showConfirmPassword)
              }
            />
          </div>

          <SubmitButton
            className="btn2 btn2-primary btn2-block font-semibold"
            isSubmitting={formikProps.isSubmitting}
            data-test="reset-password-confirm"
            style={{ marginTop: 24 }}
          >
            Update password
          </SubmitButton>
        </div>
      </Form>
      <hr className="border-cloud my-4"></hr>
      <div className="text-center">
        <a
          className="link--underline-only text-base text-dusk-500"
          href="mailto:landowners@ncx.com"
        >
          Having trouble with password reset?
          <br />
          Email us at <span className="link">landowners@ncx.com</span>
        </a>
      </div>
    </ContentCard>
  )
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please provide a new password.")
    .min(8, "New password must be at least 8 characters."),
  confirmPassword: Yup.string()
    .required("Please re-enter your new password.")
    .oneOf([Yup.ref("password")], "New passwords must match."),
})

const ResetPasswordConfirm = () => {
  const navigate = useNavigate()
  const scrubQuerystring = useScrubQuerystring()

  const token = useQueryParam("token")
  // DEV: We could create a `useState(token)` to cache our token (and update via `useEffect`)
  //   but Formik's initialValues already acts as our cache (and hard to update externally)
  //   so we're good as-is
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (token) {
      // DEV: Once this runs, we'll already have `initialValues` with `token` saved so we're golden
      scrubQuerystring() // Scrubs `token`
    }
  }, [token, scrubQuerystring])

  const { mutateAsync: resetPasswordConfirm } = useResetPasswordConfirm({
    onSuccess: () => {
      // DEV: We can auto-login since React-Query should be setting auth data from response
      navigate("/")
    },
    onError: (error: { response: { data: { password: string } } }) => {
      // If we received a validation message, then use it (e.g. `{"password":["This password is too common."]}`)
      if (error.response?.data?.password?.length) {
        setErrorMessage(error.response.data.password[0])
      } else {
        setErrorMessage(auth.genericErrMsg)
      }
    },
  })

  const handleSubmit = async ({
    token,
    password,
  }: {
    token: string | null
    password: string
  }) => {
    // DEV: We destructure to remove `confirmPassword` from values to send to server
    setErrorMessage(null)
    await resetPasswordConfirm({ token, password })
  }

  return (
    <AuthLayout
      form={
        <Formik
          initialValues={{
            token: token,
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
          children={(formikProps) => (
            <FormComponent
              errorMessage={errorMessage}
              formikProps={formikProps}
            />
          )}
        />
      }
    />
  )
}

export default ResetPasswordConfirm
