import { RefObject } from "react"
import { Link } from "react-router"

import useIsOnboarding from "../../hooks/useIsOnboarding"
import NCXLogo from "../../images/NCX---Logo---Full---White.png"
import { useSessionStore } from "../../stores"
import { useNavLinks } from "./helpers"

interface NavBarBrandProps {
  isPartnersNav: boolean
  onClick: () => void
}

const NavBarBrand = ({ isPartnersNav, onClick }: NavBarBrandProps) => {
  const isOnboarding = useIsOnboarding()
  const { logoLink } = useNavLinks(isPartnersNav)
  const { ncxLogoRef } = useSessionStore()

  if (isOnboarding) {
    return (
      <div
        ref={ncxLogoRef as unknown as RefObject<HTMLDivElement>}
        className="hidden h-full md:flex md:items-center"
      >
        <img className="h-8 w-auto" src={NCXLogo} alt="NCX Logo" />
      </div>
    )
  }

  return (
    <div className="hidden h-full md:flex md:items-center">
      <Link
        ref={ncxLogoRef}
        to={logoLink}
        className="shrink-0 p-2 -ml-2 -mr-2"
        onClick={onClick}
      >
        <img className="h-8 w-auto" src={NCXLogo} alt="NCX Logo" />
      </Link>
    </div>
  )
}

export default NavBarBrand
