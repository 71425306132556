import { Outlet } from "react-router"
import cx from "classnames"

import NavBar from "../../components/NavBar"

interface NavLayoutTypes {
  isPartnersNav?: boolean
  bgClass?: string
}

const NavLayout = ({ isPartnersNav = false, bgClass = "" }: NavLayoutTypes) => (
  <div
    className={cx("flex flex-col min-h-full", bgClass)}
    data-testid={
      isPartnersNav ? "partners-nav-layout" : "landowners-nav-layout"
    }
  >
    <NavBar isPartnersNav={isPartnersNav} />

    <Outlet />
  </div>
)

export default NavLayout
