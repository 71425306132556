import { useCallback } from "react"
import { useQueryClient } from "@tanstack/react-query"

import { useAccountId, useIsMultiAccount, useProfile } from "../../hooks"
import {
  MULTI_ACCOUNT_SLIDE_IN_LINKS,
  MULTI_ACCOUNT_TOPBAR_LINKS,
  PARTNERS_SLIDE_IN_LINKS,
  PARTNERS_TOPBAR_LINKS,
  SINGLE_ACCOUNT_SLIDE_IN_LINKS,
  SINGLE_ACCOUNT_TOPBAR_LINKS,
} from "./navbarMenuLinks"
import { useAccessToken, useSessionStore } from "../../stores"
import { NavLinksTypes } from "./types"
import { Profile } from "@/types"

export const useNavLinks = (isPartnersNav: boolean) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()

  let topbarLinks: NavLinksTypes[]
  let slideInLinks: NavLinksTypes[]
  let logoLink: string

  if (isPartnersNav) {
    logoLink = "/partners"
    topbarLinks = PARTNERS_TOPBAR_LINKS
    slideInLinks = PARTNERS_SLIDE_IN_LINKS
  } else if (isMultiAccount) {
    logoLink = "/accounts"
    topbarLinks = MULTI_ACCOUNT_TOPBAR_LINKS(accountId)
    slideInLinks = MULTI_ACCOUNT_SLIDE_IN_LINKS(accountId)
  } else {
    logoLink = "/"
    topbarLinks = SINGLE_ACCOUNT_TOPBAR_LINKS
    slideInLinks = SINGLE_ACCOUNT_SLIDE_IN_LINKS
  }

  return { logoLink, topbarLinks, slideInLinks }
}

export const usePlatformUserMenuNames = () => {
  const queryClient = useQueryClient()
  const accessToken = useAccessToken()

  const isLoggedIn = !!accessToken

  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: isLoggedIn,
  })

  const firstName = profile?.first_name || ""
  const lastName = profile?.last_name || ""
  const email = profile?.email || ""

  const fullName = `${firstName} ${lastName}`.trim() || email

  const abbreviatedName =
    `${firstName} ${lastName ? lastName.charAt(0) + "." : ""}`.trim() || email

  return { fullName, abbreviatedName }
}

export const useHandleInaccessibleAccount = (): (() => void) => {
  const accountId = useAccountId()
  const { clearInaccessibleAccountIds, inaccessibleAccountIds } =
    useSessionStore()

  const handleClick = useCallback(() => {
    if (inaccessibleAccountIds[accountId]) {
      clearInaccessibleAccountIds()
    }
  }, [accountId, clearInaccessibleAccountIds, inaccessibleAccountIds])

  return handleClick
}
