import { useQueryClient } from "@tanstack/react-query"
import { useAccount, useAccountId, useIsMultiAccount } from "./"
import { useAccessToken } from "../stores"
import { AccountTypes } from "@/types/account"

export const useIsMember = (): boolean => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const accessToken = useAccessToken()

  const isLoggedIn = !!accessToken

  const queryEnabled =
    isLoggedIn &&
    ((isMultiAccount && accountId !== "_single") || !isMultiAccount)

  const { data: account } = useAccount<AccountTypes, Error>(
    queryClient,
    accountId,
    {
      enabled: queryEnabled,
    }
  )

  const isMember = account?.is_member ?? false

  return isMember
}
