import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

import { circleQuestionMark } from "../../shared/icons"

const NavBarHelpLink = () => (
  <a
    href="https://help.ncx.com/hc/en-us"
    className="h-16 flex flex-row text-white items-center font-semibold hover:bg-nav-active px-3"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={circleQuestionMark as IconProp} size="lg" />
  </a>
)

export default NavBarHelpLink
