import { Link } from "react-router"
import { MenuItem, MenuStateReturn } from "reakit"
import { useIsMultiAccount } from "../../hooks"
import useIsEmailVerified from "../../hooks/useIsEmailVerified"
import useIsOnboarding from "../../hooks/useIsOnboarding"

interface UserMenuItemsProps {
  isPartnersNav: boolean
  menu: MenuStateReturn
}

const UserMenuItems = ({ isPartnersNav, menu }: UserMenuItemsProps) => {
  const isMultiAccount = useIsMultiAccount()
  const isEmailVerified = useIsEmailVerified()
  const isOnboarding = useIsOnboarding()

  const isEmailVerifiedLO = isEmailVerified && isPartnersNav === false

  if (!isEmailVerifiedLO || isOnboarding) {
    return null
  }

  return (
    <MenuItem
      className="block px-4 py-2 text-sm text-gray-700 focus:ring-0 focus:underline link--underline-only"
      {...menu}
      as={Link}
      to="/settings"
      onClick={menu.hide}
    >
      {/* DEV: Both are personal settings but as a single-account profile,
                everything looks like "Account Settings" so this is consistent with user expectations */}
      {isMultiAccount ? "Personal Settings" : "Account Settings"}
    </MenuItem>
  )
}

export default UserMenuItems
