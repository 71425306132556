interface UserMenuNonMemberLabelProps {
  isPartnersNav: boolean
  isMember: boolean
}

const UserMenuNonMemberLabel = ({
  isPartnersNav,
  isMember,
}: UserMenuNonMemberLabelProps) => {
  if (isMember || isPartnersNav) {
    return null
  }

  return (
    <span className="absolute left-0 right-5 bottom-[6px] sm:bottom-0.5 text-center text-sm text-west-side whitespace-nowrap">
      (non-member)
    </span>
  )
}

export default UserMenuNonMemberLabel
