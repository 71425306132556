import { NavLink } from "react-router"
import cx from "classnames"

import { NavLinksTypes } from "./types"

interface NavBarLinksProps {
  showLinks: boolean
  topbarLinks: NavLinksTypes[]
  onClick: () => void
}

const NavBarLinks = ({ showLinks, topbarLinks, onClick }: NavBarLinksProps) => {
  if (!showLinks) {
    return null
  }

  return topbarLinks.map((link) => (
    <li className="h-16 flex flex-row" key={link.href}>
      <NavLink
        to={link.href}
        className={({ isActive }) =>
          cx(
            "flex items-center justify-center text-base w-[132px] h-full font-semibold hover:bg-nav-active gap-1",
            {
              "bg-nav-active border-b-4 border-white": isActive,
            }
          )
        }
        onClick={onClick}
        end
      >
        {link.title}
      </NavLink>
    </li>
  ))
}

export default NavBarLinks
