import { useMemo } from "react"
import { Disclosure, DisclosureContentOptions } from "reakit"

import NCXLogo from "../../images/NCX---Logo---Full---White.png"
import { NavLinksTypes } from "./types"

interface NavbarmenuToggleProps {
  showLinks: boolean
  disclosure: DisclosureContentOptions
  topbarLinks: NavLinksTypes[]
}

const NavBarMenuToggle = ({
  showLinks,
  disclosure,
  topbarLinks,
}: NavbarmenuToggleProps) => {
  const activeTopbarLink = useMemo(
    () =>
      topbarLinks.find(
        (link: NavLinksTypes) => location.pathname === link.href
      ),
    [topbarLinks]
  )

  return (
    <div className="md:hidden">
      {showLinks ? (
        <Disclosure
          {...disclosure}
          className="btn2 px-2 py-1 text-white hover:bg-brand-dark"
        >
          <span className="sr-only">Open menu</span>
          <i aria-hidden="true" className="fas fa-bars h-6 w-6" />
        </Disclosure>
      ) : (
        <img className="h-8 w-auto" src={NCXLogo} alt="NCX Logo" />
      )}

      {activeTopbarLink && (
        <span className="text-white ml-2 hidden sm:inline">
          {activeTopbarLink?.title}
        </span>
      )}
    </div>
  )
}

export default NavBarMenuToggle
