import { useState, ReactNode } from "react"
import { AxiosError } from "axios"
import {
  QueryCache,
  QueryClient,
  QueryClientProvider as RQProvider,
  Query,
  QueryKey,
} from "@tanstack/react-query"

import { AuthData, useSessionStore } from "../stores"

// DEV: The useConstant function is a custom hook that uses the useState hook to create a constant value.
// The purpose of this custom hook is to ensure that the QueryClient instance is only created once and remains constant throughout the component's lifecycle.
const useConstant = <T,>(fn: () => T): T => useState(fn)[0]

interface QueryClientProviderProps {
  children: ReactNode
}

interface MetaData extends Record<string, unknown> {
  accountId?: string
  refresh?: boolean
}

type CustomQuery<TData = unknown, TError = unknown> = Query<
  TData,
  TError,
  TData,
  QueryKey
> & {
  meta?: MetaData
}

function QueryClientProvider({ children }: QueryClientProviderProps) {
  const { setInaccessibleAccountId, setAuthData } = useSessionStore(
    (state) => state
  )

  // DEV: The QueryClientProvider component utilizes useConstant to create a constant queryClient instance.
  // This ensures that the QueryClient is instantiated only once, even if the QueryClientProvider component re-renders.
  const queryClient = useConstant(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { refetchOnWindowFocus: false, networkMode: "offlineFirst" },
          mutations: {
            networkMode: "offlineFirst",
          },
        },
        queryCache: new QueryCache({
          onError: (error: Error, query: CustomQuery) => {
            const axiosError = error as AxiosError
            if (
              axiosError.response?.status === 404 &&
              query?.meta?.accountId !== undefined
            ) {
              setInaccessibleAccountId(query.meta.accountId)
            }
          },
          onSuccess: (data: unknown, query: CustomQuery) => {
            if (query?.meta?.refresh) {
              setAuthData(data as AuthData)
            }
          },
        }),
      })
  )

  return <RQProvider client={queryClient}>{children}</RQProvider>
}

export { QueryClientProvider }
