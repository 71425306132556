import { NavLink } from "react-router"
import { DisclosureStateReturn } from "reakit"
import cx from "classnames"

import { useHandleInaccessibleAccount, useNavLinks } from "./helpers"

interface SlideInNavLinksProps {
  isPartnersNav: boolean
  disclosure: DisclosureStateReturn
}

const SlideInNavLinks = ({
  isPartnersNav,
  disclosure,
}: SlideInNavLinksProps) => {
  const handleInaccessibleAccount = useHandleInaccessibleAccount()
  const { slideInLinks } = useNavLinks(isPartnersNav)

  return (
    <nav className="w-full">
      {slideInLinks.map((item) => (
        <NavLink
          to={item.href}
          className={({ isActive }) => {
            return cx(
              "block p-3 rounded-md  text-lg font-bold leading-[140%] tracking-0.36 link--underline-only",
              isActive ? "text-moss" : "text-white"
            )
          }}
          onClick={() => {
            handleInaccessibleAccount()
            disclosure.hide()
          }}
          key={item.title}
        >
          {item.title}
        </NavLink>
      ))}
    </nav>
  )
}

export default SlideInNavLinks
